import React from 'react';

function Home() {
  return (
    <div className="home">
        <img
          src="manduba_flyer_30.jpeg"
          className="flyer"
          alt="Manduba"
        />
    </div>
  );
}

export default Home;