import React from 'react';

function Images() {
  return (
    <div className="images">
      Coming soon :)
      </div>
  );
}

export default Images;