import React from 'react';

function Contact() {
  return (
    <div className="contact">
            Coming soon :)
    </div>
  );
}

export default Contact;