import React from "react";

function Info() {
  return (
    <div className="info">
      <div className="info_container">
        <p>
          Manduba is a dub live trío based in Barcelona.
          </p>
          <p> For more than 20 years
          they have been mixing reggae dub and its siblings with dubstep and
          steppas beats, original songs, live vocals and brasses.
        </p>
        <p>
          In this moment of their stay in this planet, they converge their
          energies with a versatile format that allows them to deliver a high
          energy and atmospheric concert or take control of a soundsystem with a
          live show.
        </p>
      </div>
    </div>
  );
}

export default Info;
